.landing-container {
	.image {
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;

		.landing-motion {
			height: 250px;
			width: 650px;

			display: flex;
			justify-content: center;
			align-items: center;

			border: 2px solid #e28413;
			background-color: rgba(0, 0, 0, 0.65);

			.landing-logo {
				margin-top: 20px;
				transform: scale(2.5);
				filter: invert(100%) sepia(73%) saturate(582%)
					hue-rotate(186deg) brightness(118%) contrast(95%);
			}

			.langing-txt {
				margin-top: 20px;
				color: #f8f9fa;
				font-size: 24px;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.landing-container {
		.image {
			.landing-motion {
				width: 90%;

				.landing-logo {
					transform: scale(1.2);
				}

				.langing-txt {
					font-size: 22px;
				}
			}
		}
	}
}
