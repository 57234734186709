.pro-container {
	width: 80%;
	margin: auto;
	padding-top: 150px;
	overflow-x: hidden;

	.text2 {
		margin-top: 80px;
	}

	.text3 {
		margin-top: 10px;
	}

	.photos-container {
		margin-top: 100px;
		margin-bottom: 10px;

		display: flex;
		justify-content: space-evenly;

		.pcb-photo {
			height: 300px;
		}

		@media screen and (max-width: 1600px) {
			display: block;

			.pcb-photo {
				display: block;
				margin: 50px auto;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.pro-container {
		width: 100%;
		padding-top: 50px;

		.text2 {
			width: 80%;
		}

		.text3 {
			width: 80%;
		}

		.photos-container {
			margin: auto;
			margin-top: 50px;
			width: 80%;

			.pcb-photo {
				width: 90%;
				height: 90%;
			}

			@media screen and (max-width: 1600px) {
				display: block;

				.pcb-photo {
					display: block;
					margin: 50px auto;
				}
			}
		}
	}
}
