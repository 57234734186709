.con-container {
	width: 80%;
	margin: auto;
	padding-top: 150px;

	.text1 {
		text-align: center;
		margin-top: 30px;
	}

	.contact-form {
		width: 400px;
		margin: auto;
		margin-top: 30px;
		margin-bottom: 50px;

		.input-container {
			width: 400px;

			margin-top: 20px;
			padding: 5px 15px;

			display: flex;
			border: 1px solid #2a305f;
			border-radius: 5px;

			.logo {
				height: 30px;
				display: block;
				stroke: #e28413;
			}

			input {
				width: 300px;
				display: block;
				margin-left: 10px;
				border: none;
				background-color: #f8f9fa;
				font-size: 20px;
				outline: none;
			}

			textarea {
				height: 250px;
				width: 100%;
				border: none;
				background-color: #f8f9fa;
				font-size: 20px;
				outline: none;
				resize: none;
				color: #343a40;
			}
		}

		.contact-btn {
			display: block;

			width: 250px;

			margin: auto;
			margin-top: 30px;
			padding: 10px 30px;
			text-align: center;

			font-size: 24px;
			text-decoration: none;
			background-color: #e28413;
			border-radius: 5px;

			color: #f8f9fa;

			transition: all 0.5s;

			&:hover {
				background-color: #f1a142;
				transition: all 0.5s;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.con-container {
		padding-top: 50px;

		.contact-form {
			width: 100%;

			.input-container {
				width: 100%;

				input {
					width: 100%;
				}

				textarea {
					height: 200px;
				}
			}
		}
	}
}
