.navbar-container {
	background-color: rgba(0, 0, 0, 0.6);
	height: 100px;
	width: 100%;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;

	display: flex;
	justify-content: space-between;
	align-items: center;

	.navbar-logo-container {
		margin-left: 100px;

		.navbar-logo {
			display: block;
			height: 70px;
			filter: invert(100%) sepia(73%) saturate(582%) hue-rotate(186deg)
				brightness(118%) contrast(95%);
			transition: all 0.3s;

			&:hover {
				cursor: pointer;
				filter: invert(78%) sepia(77%) saturate(3403%)
					hue-rotate(345deg) brightness(87%) contrast(104%);
				transition: all 0.3s;
			}
		}
	}

	.navbar-cat-container {
		display: flex;
		margin-right: 100px;
		justify-content: space-around;
		width: 500px;

		.navbar-cat {
			color: #f8f9fa;
			font-size: 24px;

			transition: all 0.5s;

			&:hover {
				cursor: pointer;
				color: #e28413;
				transition: all 0.5s;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.navbar-container {
		display: none;
	}
}
