.about-container {
	width: 80%;
	margin: auto;
	padding-top: 150px;

	.logo-container {
		margin-top: 150px;

		display: flex;
		justify-content: space-around;

		.logo {
			height: 80px;
		}
	}
}

@media screen and (max-width: 500px) {
	.about-container {
		width: 80%;
		padding-top: 50px;

		.logo-container {
			margin-top: 50px;
			display: block;

			.logo {
				height: 70px;
				display: inline-block;
			}
		}
	}
}
