.title {
	text-align: center;
	font-size: 88px;
	font-weight: 500;
	letter-spacing: 5px;
	color: #2a305f;
}

.text {
	font-size: 18px;
	margin: auto;
	margin-top: 100px;
	letter-spacing: 1px;
	line-height: 1.6;
	text-align: justify;
}

footer {
	background-color: #2a305f;
	height: 50px;
	color: #f8f9fa;
	display: flex;
	align-items: center;
	padding-left: 30px;
}

@media screen and (max-width: 1000px) and (min-width: 501px) {
	.text {
		margin-top: 50px;
	}
}

@media screen and (max-width: 500px) {
	.title {
		font-size: 50px;
	}

	.text {
		margin-top: 50px;
	}
}
