* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	background-color: #f8f9fa;
	font-family: "Ubuntu", sans-serif;
}

p {
	color: #343a40;
}
